<template>
  <input
    type="checkbox"
    class="toggle-checkbox hidden"
    :class="isActive ? 'active' : ''"
    :checked="isActive"
    @click="toggle" />
  <div
    :class="[
      'toggle-slot relative h-5 w-10 rounded-full',
      props.toggleable ? 'cursor-pointer' : 'cursor-not-allowed',
      isActive ? 'bg-rezlock' : 'bg-gray-400',
    ]"
    @click="toggle">
    <div
      class="toggle-dot absolute m-0.5 h-4 w-4 transform rounded-full bg-light shadow transition-transform"
      :class="{ 'translate-x-5': isActive }"></div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  id: Number,
  checked: Boolean,
  toggleable: Boolean,
});

const emit = defineEmits(['update:checked']);
const isActive = ref(props.checked);

const toggle = () => {
  if (props.toggleable) {
    isActive.value = !isActive.value;
    emit('update:checked', isActive.value);
  }
};

watch(
  () => props.checked,
  (value) => {
    isActive.value = value;
  },
);
</script>

<style scoped>
.toggle-checkbox {
  @apply hidden;
}
.toggle-checkbox.active + .toggle-slot {
  @apply bg-rezlock;
}
.toggle-dot {
  transition: transform 0.3s;
}
.translate-x-7 {
  transform: translateX(1.75rem);
}
</style>
