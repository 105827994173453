<template>
  <div
    class="flex-grow content-center overflow-y-auto text-small-titles md:my-9">
    <div class="mb-4">
      {{ $t('cookies_settings') }}
    </div>

    <div class="mb-4 text-sm" v-html="$t('privatePoliciesTitle')"></div>
    <div class="mb-4 text-sm" v-html="$t('privatePoliciesText')"></div>

    <hr class="mt-4 border-border" />
    <div class="my-4 flex items-center justify-between">
      <div>{{ $t('strictly_necessary_cookies_title') }}</div>
      <CustomSwitch :id="id" :checked="necessaryCookies" />
    </div>
    <div class="text-sm">
      <div v-if="showDetails.strictlyNecessary">
        <div>{{ $t('strictly_necessary_cookies') }}</div>
        <CookiesTable :cookiesData="necessaryCookiesData" />
      </div>
      <button class="underline" @click="toggleDetails('strictlyNecessary')">
        {{ showDetails.strictlyNecessary ? $t('seeLess') : $t('seeDetails') }}
      </button>
    </div>

    <hr class="mt-4 border-border" />
    <div class="my-4 flex items-center justify-between">
      <div>{{ $t('analysis_cookies_title') }}</div>
      <CustomSwitch
        toggleable
        :id="id"
        :checked="analisysCookies"
        @update:checked="onToggleAnalisysCookies" />
    </div>
    <div class="text-sm">
      <div v-if="showDetails.analysis">
        <div>{{ $t('analysis_cookies') }}</div>
        <CookiesTable :cookiesData="analisysDataCookies" />
      </div>

      <button class="underline" @click="toggleDetails('analysis')">
        {{ showDetails.analysis ? $t('seeLess') : $t('seeDetails') }}
      </button>
    </div>

    <hr class="mt-4 border-border" />
    <div class="my-4 flex items-center justify-between">
      <div>{{ $t('functionality_or_customisation_cookies_title') }}</div>

      <CustomSwitch
        toggleable
        :id="id"
        :checked="functionalityCookies"
        @update:checked="onToggleFunctionalityCookies" />
    </div>
    <div class="mb-2 text-sm">
      <div v-if="showDetails.functionality">
        <div>{{ $t('functionality_or_customisation_cookies') }}</div>

        <CookiesTable :cookiesData="functionalityDataCookies" />
      </div>
      <button class="underline" @click="toggleDetails('functionality')">
        {{ showDetails.functionality ? $t('seeLess') : $t('seeDetails') }}
      </button>
    </div>
  </div>

  <div>
    <CustomButton
      class="mb-2 w-full"
      customClass="primary"
      width="max-w-full"
      :label="$t('accept_all_cookies')"
      @click="onAcceptAllCookies" />

    <CustomButton
      class="mb-2 w-full"
      customClass="secondary"
      width="max-w-full"
      :label="$t('confirm_preferences')"
      @click="onConfirmPreferences" />

    <CustomButton
      class="mb-4 w-full"
      customClass="secondary"
      width="max-w-full"
      :label="$t('general.rejectAllCookies')"
      @click="onRejectAllCookies" />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useCookiesStore } from '@/stores/cookies.store';
import CustomButton from '@/components/customComponents/CustomButton.vue';
import CustomSwitch from '@/components/customComponents/CustomSwitch.vue';
import CookiesTable from '@/components/Cookies/CookiesTable.vue';
import {
  necessaryCookiesData,
  analisysDataCookies,
  functionalityDataCookies,
} from '@/tests/cookiesMock.js';
import { storeToRefs } from 'pinia';

const { acceptAllCookies, rejectAllCookies, confirmPreferences } =
  useCookiesStore();

const { userCookies } = storeToRefs(useCookiesStore());

const emit = defineEmits(['closeModal']);

onMounted(() => {
  if (userCookies.value) {
    necessaryCookies.value = userCookies.value.necessaryCookies;
    analisysCookies.value = userCookies.value.analyticsCookies;
    functionalityCookies.value =
      userCookies.value.functionalityOrPersonalizationCookies;
  }
});

const necessaryCookies = ref(true);
const analisysCookies = ref(false);
const functionalityCookies = ref(false);

const showDetails = ref({
  strictlyNecessary: false,
  analysis: false,
  functionality: false,
});

const onToggleAnalisysCookies = (value) => {
  analisysCookies.value = value;
};

const onToggleFunctionalityCookies = (value) => {
  functionalityCookies.value = value;
};

const onAcceptAllCookies = () => {
  analisysCookies.value = true;
  functionalityCookies.value = true;
  acceptAllCookies();
  emit('closeModal');
};

const onRejectAllCookies = () => {
  analisysCookies.value = false;
  functionalityCookies.value = false;
  rejectAllCookies();
  emit('closeModal');
};

const onConfirmPreferences = () => {
  confirmPreferences({
    necessaryCookies: true,
    analyticsCookies: analisysCookies.value,
    functionalityOrPersonalizationCookies: functionalityCookies.value,
  });
  emit('closeModal');
};

const toggleDetails = (type) => {
  showDetails.value[type] = !showDetails.value[type];
};
</script>
