import { ref } from 'vue';
import { useScreenSize } from '@composables/useScreenSize';
import { useAuthStore } from '@/stores/auth.store';

// Create a singleton instance
const isInitialized = ref(false);
const isGtmInitialized = ref(false);
let gaInstance = null;

/**
 * Hook for Google Analytics and Google Tag Manager integration
 * @returns {Object} Object containing analytics methods and state
 */
export const useGoogleAnalytics = () => {
  if (gaInstance) {
    return gaInstance;
  }

  /**
   * Initializes Google Analytics and Google Tag Manager
   * @param {Object} gaConfig - Configuration object for analytics
   * @param {string} gaConfig.mbg_ga_code - Google Analytics tracking ID for mobile
   * @param {string} gaConfig.bg_ga_code - Google Analytics tracking ID for desktop
   * @param {string} gaConfig.gtm_container_id - Google Tag Manager container ID
   * @returns {void}
   */
  const initializeAnalytics = (gaConfig) => {
    if (!gaConfig) {
      console.warn('Google Analytics configuration is required');
      return;
    }

    const { isMobile } = useScreenSize();
    const trackingId = isMobile.value
      ? gaConfig.mbg_ga_code
      : gaConfig.bg_ga_code;
    const gtmContainerId = gaConfig.gtm_container_id;

    if (!trackingId) {
      console.warn('No valid Google Analytics tracking ID found');
    } else {
      loadGoogleAnalytics(trackingId);
    }

    if (!gtmContainerId) {
      console.warn('No valid Google Tag Manager container ID found');
    } else {
      loadGoogleTagManager(gtmContainerId);
    }
  };

  /**
   * Loads Google Analytics script and initializes tracking
   * @param {string} trackingId - Google Analytics tracking ID
   * @returns {void}
   */
  const loadGoogleAnalytics = (trackingId) => {
    if (!trackingId) {
      console.warn('Google Analytics tracking ID is required');
      return;
    }

    if (isInitialized.value) {
      return;
    }

    try {
      // Check if script already exists
      const existingScript = document.querySelector(
        `script[src*="googletagmanager.com/gtag/js"]`,
      );
      if (existingScript) {
        isInitialized.value = true;
        return;
      }

      // Create script element
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

      // Add load event listener to ensure GA is ready
      script.onload = () => {
        // Initialize gtag
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', trackingId);

        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          analytics_storage: 'granted',
        });

        isInitialized.value = true;
      };

      script.onerror = (error) => {
        console.error('Failed to load Google Analytics:', error);
        isInitialized.value = false;
      };

      document.head.appendChild(script);
    } catch (error) {
      console.error('Error initializing Google Analytics:', error);
      isInitialized.value = false;
    }
  };

  /**
   * Disables Google Analytics tracking for the specified tracking ID
   * @param {string} trackingId - Google Analytics tracking ID
   * @returns {void}
   */
  const disableTracking = (trackingId) => {
    if (trackingId) {
      window[`ga-disable-${trackingId}`] = true;
    }
  };

  /**
   * Loads Google Tag Manager script and initializes container
   * @param {string} containerId - Google Tag Manager container ID
   * @returns {void}
   */
  const loadGoogleTagManager = (containerId) => {
    if (!containerId) {
      console.warn('Google Tag Manager container ID is required');
      return;
    }

    if (isGtmInitialized.value) {
      return;
    }

    try {
      // Check if GTM script already exists
      const existingScript = document.querySelector(
        `script[src*="googletagmanager.com/gtm.js"]`,
      );
      if (existingScript) {
        isGtmInitialized.value = true;
        return;
      }

      // Initialize dataLayer
      ensureDataLayer();

      // Add GTM script
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', containerId);

      // Add GTM noscript iframe for browsers with JavaScript disabled
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${containerId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.insertBefore(noscript, document.body.firstChild);

      isGtmInitialized.value = true;
    } catch (error) {
      console.error('Error initializing Google Tag Manager:', error);
      isGtmInitialized.value = false;
    }
  };

  // Get auth store for channelKey
  const authStore = useAuthStore();

  /**
   * Ensures dataLayer exists in the window object
   * @returns {Array} The dataLayer array
   */
  const ensureDataLayer = () => {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  };

  /**
   * Tracks a page view in Google Analytics
   * @param {string} path - The page path to track
   * @param {string} [hotelId] - Optional hotel ID to prepend to the path
   * @returns {void}
   */
  const trackPageView = (path, hotelId) => {
    if (!isInitialized.value || !window.gtag) return;
    window.gtag('event', 'page_view', {
      page_path: hotelId ? `${hotelId}${path}` : path,
    });
  };

  /**
   * Tracks a booking event with both standard GTM format and enhanced ecommerce
   * @param {Array<Object>} reservations - Array of reservation objects
   * @returns {void}
   */
  const trackBookedEventEcommerce = (reservations) => {
    // Check if GTM is initialized
    if (!isGtmInitialized.value) {
      console.warn('Google Tag Manager is not initialized');
      return;
    }

    // Also track with the original format for backward compatibility
    // Iterate through each reservation and push to dataLayer
    reservations.forEach((res) => {
      // Calculate number of nights
      const checkIn = new Date(res.checkin);
      const checkOut = new Date(res.checkout);
      const nights = Math.round((checkOut - checkIn) / (1000 * 60 * 60 * 24));

      //Create a new array of objects for addons
      const addons = res.addons
        ? res.addons.map((addon) => ({
            name: addon.name,
            netPriceTotal: addon.net_price,
          }))
        : [];

      //create reservation object
      const gcReservation = {
        code: res.hotel_reservation_code,
        propertyName: res.hotel.hotel_name,
        offer: res.room_type.name + ' - ' + res.rate_plan.name,
        offerId: res.room_rate.room_rate_code,
        mealPlan: res.meal_plan.name,
        checkIn: res.checkin,
        checkOut: res.checkout,
        nights: nights,
        quantity: 1,
        adults: res.number_of_adults,
        children: res.number_of_children,
        addons: res.addons || [],
      };

      //create price object
      const gcPrice = {
        currency: res.room_rate.currency,
        total: res.room_rate.total_price,
        net: res.room_rate.net_price,
        netAddons:
          addons && addons.length > 0
            ? addons.reduce((sum, addon) => sum + addon.netPriceTotal, 0)
            : 0,
        netStay: res.room_rate.net_price,
        taxes: res.room_rate.taxes,
        fees: res.room_rate.fees,
        perUnit: res.room_rate.net_price,
      };

      //create guest object
      const gcGuest = {
        firstName: res.guest.first_name,
        lastName: res.guest.last_name,
        name: `${res.guest.first_name} ${res.guest.last_name}`,
        city: res.guest.city,
        zip: res.guest.postal_code,
        country: res.guest.country,
        payment: res.credit_card ? res.credit_card.name || '' : '',
      };

      const transProducts = res.addons
        ? res.addons.map((addon) => ({
            item_id: addon.name,
            item_name: addon.name,
            item_category: 'addon',
            price: parseFloat(addon.net_price),
            quantity: addon.quantity,
          }))
        : [];

      //create ecommerce object
      const gcEcommerce = {
        event: 'purchase',
        transaction_id: res.hotel_reservation_code,
        affiliation: authStore.channelKey ? authStore.channelKey : '',
        value: res.room_rate.total_price,
        currency: res.room_rate.currency,
        tax: res.room_rate.taxes,
        items: transProducts,
      };

      const eventData = {
        event: 'booked',
        // Reservation details
        gcReservation: gcReservation,
        //price details
        gcPrice: gcPrice,
        // Guest details
        gcGuest: gcGuest,
        // Metadata fields
        gcReferer: res.booking_referer,
        gcLanguage: res.guest.language,
        gcCustomParam: res.custom_param || '',
        gcMobile: useScreenSize().isMobile ? true : false,
        gcClientId: res.hotel.hotel_id,
        gcChannelKey: authStore.channelKey ? authStore.channelKey : '',
        // ecommerce
        transactionId: res.hotel_reservation_code,
        transactionAffiliation: authStore.channelKey
          ? authStore.channelKey
          : '',
        transactionTotal: res.room_rate.total_price,
        transactionTax: res.room_rate.taxes,
        transactionShipping: '-',
        transactionProducts: transProducts,
        ecommerce: gcEcommerce,
      };

      // Push to dataLayer
      pushToDataLayer(eventData);
    });
  };

  /**
   * Pushes data to the Google Tag Manager dataLayer
   * @param {Object} data - Data object to push to the dataLayer
   * @returns {void}
   */
  const pushToDataLayer = (data) => {
    if (!isGtmInitialized.value) {
      console.warn('Google Tag Manager is not initialized');
      return;
    }

    const dataLayer = ensureDataLayer();
    dataLayer.push(data);
  };

  /**
   * Pushes a standard event to the Google Tag Manager dataLayer
   * @param {string} eventName - Name of the event
   * @param {Object} [eventData={}] - Additional event data
   * @returns {void}
   */
  const pushEvent = (eventName, eventData = {}) => {
    pushToDataLayer({
      event: eventName,
      ...eventData,
    });
  };

  /**
   * Clears previous ecommerce object from dataLayer to prevent data leakage
   * @returns {void}
   */
  const clearEcommerceData = () => {
    pushToDataLayer({
      ecommerce: null,
    });
  };

  /**
   * Tracks an event in both Google Analytics and Google Tag Manager
   * @param {string} eventName - Name of the event
   * @param {Object} [eventParams={}] - Additional event parameters
   * @returns {void}
   */
  const trackEvent = (eventName, eventParams = {}) => {
    // Track with GA if initialized
    if (isInitialized.value && window.gtag) {
      window.gtag('event', eventName, eventParams);
    }

    // Also track with GTM if initialized
    if (isGtmInitialized.value) {
      pushEvent(eventName, eventParams);
    }
  };

  gaInstance = {
    isInitialized,
    isGtmInitialized,
    initializeAnalytics,
    trackPageView,
    trackEvent,
    trackBookedEventEcommerce,
    pushToDataLayer,
    pushEvent,
    clearEcommerceData,
    disableTracking,
  };

  return gaInstance;
};
