<template>
  <footer class="flex justify-center">
    <PoweredGc class="mt-3.5 h-3" />
    <span
      v-if="env.toLowerCase() === 'development'"
      class="gc-font-notes ml-5 flex items-end">
      Version: {{ version }}
    </span>
  </footer>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';
const PoweredGc = defineAsyncComponent(() => import('@icons/powerby.svg'));
const env = import.meta.env.MODE;
const version = import.meta.env.APP_VERSION;
</script>
