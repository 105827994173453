import { onMounted, ref } from 'vue';
import { defineStore } from 'pinia';
import { useGoogleAnalytics } from '@/services/analytics';

export const useCookiesStore = defineStore('cookiesStore', () => {
  const userCookies = ref({
    necessaryCookies: true,
    analyticsCookies: false,
    functionalityOrPersonalizationCookies: false,
  });

  onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ckParam = urlParams.get('ck');
    if (ckParam && !sessionStorage.getItem('userCookies')) {
      const activatedCookies = ckParam.split('');
      userCookies.value.analyticsCookies = activatedCookies[1] === '1';
      userCookies.value.functionalityOrPersonalizationCookies =
        activatedCookies[2] === '1';
      saveCookieInSession();
    } else {
      userCookies.value = JSON.parse(sessionStorage.getItem('userCookies'));
    }
  });

  const handleAnalyticsCookieChange = (enabled) => {
    const { initializeAnalytics, disableTracking } = useGoogleAnalytics();

    if (enabled) {
      console.log('Initializing Google Analytics');
      initializeAnalytics(window._gaConfig);
    } else {
      const trackingId = window._gaConfig?.bg_ga_code;
      if (trackingId) {
        disableTracking(trackingId);
      }
    }
  };

  const acceptAllCookies = () => {
    userCookies.value = {
      necessaryCookies: true,
      analyticsCookies: true,
      functionalityOrPersonalizationCookies: true,
    };

    handleAnalyticsCookieChange(userCookies.value.analyticsCookies);

    saveCookieInSession();
  };

  const rejectAllCookies = () => {
    userCookies.value = {
      necessaryCookies: true,
      analyticsCookies: false,
      functionalityOrPersonalizationCookies: false,
    };

    handleAnalyticsCookieChange(userCookies.value.analyticsCookies);

    saveCookieInSession();
  };

  const confirmPreferences = (cookies) => {
    userCookies.value = cookies;

    handleAnalyticsCookieChange(userCookies.value.analyticsCookies);

    saveCookieInSession();
  };

  const saveCookieInSession = () => {
    sessionStorage.setItem('userCookies', JSON.stringify(userCookies.value));
  };

  return {
    userCookies,
    acceptAllCookies,
    rejectAllCookies,
    confirmPreferences,
  };
});
