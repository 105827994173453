export class AuthenticationError extends Error {
  constructor(message, code = 'AUTH_ERROR', originalError = null) {
    super(message);
    this.name = 'AuthenticationError';
    this.code = code;
    this.originalError = originalError;
  }
}

export const AUTH_ERROR_CODES = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  TOKEN_REFRESH_FAILED: 'TOKEN_REFRESH_FAILED',
  MISSING_PARAMS: 'MISSING_PARAMS',
  NETWORK_ERROR: 'NETWORK_ERROR',
};
