<template>
  <div class="flex h-full w-full flex-col justify-between">
    <!-- Main content in the middle -->
    <div class="flex-grow content-center">
      <h1
        class="gc-font-title mb-10 mt-5 text-center font-text-pop-title text-text-pop-title">
        {{ applicationSetup.loyalty.button_label }}
      </h1>

      <CustomInput
        id="name"
        customDivClass="mb-4 w-full text-xs"
        customLabelClass="text-small-titles"
        customInputClass="w-full mt-1 h-[46px]"
        :class="{ warning: nameWarning }"
        :label="$t('general.name')"
        placeholder="John Doe"
        @update:text="fillName" />
      <CustomInput
        id="signUpemail"
        customDivClass="mb-4 text-xs w-full"
        customLabelClass="text-small-titles"
        customInputClass="w-full mt-1 h-[46px]"
        :class="{ warning: emailWarning }"
        :label="$t('general.email')"
        placeholder="john.doe@mail.com"
        @update:text="fillEmail" />
      <CustomSelect
        iconClass="mr-3 w-6 h-6"
        :containerClass="['signUpCountry', countryWarning ? 'warning' : '']"
        :title="$t('general.country')"
        :options="countryList"
        :searchable="true"
        label="label"
        labelClass="!font-normal !mb-1 !text-xs text-small-titles mb-0"
        optionClass="ml-0"
        :selectedObject="selectedCountryObject"
        @changedOption="handleCountryChange"
        @onMultiselectClose="onMultiselectClose" />

      <div class="mt-10 flex justify-center">
        <div class="text-sm">
          {{ $t('general.alreadyMember') }} ?
          <a
            href="#"
            class="cursor-pointer text-brand underline"
            @click="goToLogin">
            {{ $t('general.login') }}
          </a>
        </div>
      </div>

      <div
        class="mb-2 mt-10 flex items-center"
        :class="{ warning: termsWarning }">
        <input
          id="accept-terms"
          name="accept-terms"
          type="checkbox"
          v-model="acceptTerms"
          @change="handleTermsChange"
          class="mr-2" />
        <label class="underline-on-hover text-xs" @click.stop="viewPolicies">
          {{ $t('GDPRShortConsentText') }}
        </label>
      </div>
    </div>
    <div class="mb-5 mt-10 flex justify-around gap-2">
      <CustomButton
        class="signUpButton"
        width="w-full"
        :label="$t('general.signUp')"
        @click="doSignUp" />
    </div>
    <InformativeModal
      :isVisible="showInvalidEmail"
      :title="$t('general.emailAlreadyExists')"
      :description="$t('general.emailInUse')"
      :buttonText="$t('general.closeButtonText')"
      type="warning"
      @update:isVisible="onInvalidMailModalClose" />
  </div>

  <CustomDrawer
    v-model="policiesVisible"
    :position="isMobile ? 'bottom' : 'right'"
    :width="isMobile ? 'w-full' : 'w-[35%]'"
    height="h-auto">
    <template #drawerContent>
      <GDPRPolicyText @close="policiesVisible = false" />
    </template>
  </CustomDrawer>
</template>

<script setup>
import {
  ref,
  defineAsyncComponent,
  inject,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import { useUserProfile } from '@stores/userProfile.store';
import CustomInput from '@/components/customComponents/CustomInput.vue';
import CustomButton from '@/components/customComponents/CustomButton.vue';
import CustomSelect from '@/components/customComponents/CustomSelect.vue';
import GDPRPolicyText from '@/components/PersonalData/GDPRPolicyText.vue';
import InformativeModal from '@/components/customComponents/InformativeModal.vue';

import { useScreenSize } from '@composables/useScreenSize';
import { validateEmail } from '@composables/useEmailValidator';
const userProfile = useUserProfile();

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const CustomDrawer = defineAsyncComponent(
  () => import('@/components/customComponents/CustomDrawer.vue'),
);

const emit = defineEmits([
  'backToLogin',
  'showSignupModal',
  'isSignUpEmptyOrValid',
]);

const { isMobile } = useScreenSize();

const policiesVisible = ref(false);
const acceptTerms = ref(false);
const applicationSetup = inject('applicationSetup');

const name = ref('');
const email = ref('');

const nameWarning = ref(false);
const emailWarning = ref(false);
const countryWarning = ref(false);
const termsWarning = ref(false);

const showInvalidEmail = ref(false);

const selectedCountryObject = ref(null);

const handleKeydown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    doSignUp();
  }
};

onMounted(() => {
  selectedCountryObject.value = countryList.value[0];
  if (applicationSetup.value.userCountryCode) {
    const userCountry = countryList.value.find(
      (country) => country.value == applicationSetup.value.userCountryCode,
    );

    if (userCountry) {
      selectedCountryObject.value = userCountry;
    }
  }
  window.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeydown);
});

const onMultiselectClose = () => {
  const selectedOption = document.querySelector(
    '.signUpCountry .multiselect__option--highlight span',
  );
  if (selectedOption) {
    const selectedText = selectedOption.textContent.trim();
    selectedCountryObject.value = countryList.value.find(
      (country) => country.label.toLowerCase() === selectedText.toLowerCase(),
    );
    countryWarning.value = false;
  }
};

const onInvalidMailModalClose = () => {
  emit('backToLogin');
  showInvalidEmail.value = false;
};

const goToLogin = () => {
  emit('backToLogin');
};

const validateFields = () => {
  let isValid = true;

  if (!name.value) {
    nameWarning.value = true;
    isValid = false;
  } else {
    nameWarning.value = false;
  }

  if (!email.value) {
    emailWarning.value = true;
    isValid = false;
  } else if (!validateEmail(email.value)) {
    emailWarning.value = true;
    isValid = false;
  } else {
    emailWarning.value = false;
  }

  if (selectedCountryObject.value.value === '') {
    countryWarning.value = true;
    isValid = false;
  } else {
    countryWarning.value = false;
  }

  if (!acceptTerms.value) {
    termsWarning.value = true;
    isValid = false;
  } else {
    termsWarning.value = false;
  }

  return isValid;
};

const doSignUp = async () => {
  if (!name.value && !email.value && selectedCountryObject.value.value === '') {
    policiesVisible.value = false;
    emit('close');
    return;
  }

  const valid = validateFields();
  if (!valid) {
    return;
  }

  const userData = {
    name: name.value,
    login: email.value,
    language: applicationSetup.value.language,
    country_code: selectedCountryObject.value.value,
  };

  try {
    const response = await userProfile.doSignUp(userData);

    if (response.data.already_registered) {
      showInvalidEmail.value = true;
      return;
    }

    emit('showSignupModal');
  } catch (error) {
    console.error('Sign up failed:', error);
  }
};

const viewPolicies = () => {
  policiesVisible.value = true;
};

const fillName = (value) => {
  name.value = value;
  nameWarning.value = !value;
  emit('isSignUpEmptyOrValid', checkFormValidity());
};

const fillEmail = (value) => {
  email.value = value;
  emailWarning.value = false;
  emit('isSignUpEmptyOrValid', checkFormValidity());
};

const handleCountryChange = (selectedCountry) => {
  selectedCountryObject.value = selectedCountry.value;
  countryWarning.value = false;
  emit('isSignUpEmptyOrValid', checkFormValidity());
};

const handleTermsChange = () => {
  termsWarning.value = !acceptTerms.value;
  emit('isSignUpEmptyOrValid', checkFormValidity());
};

const checkFormValidity = () => {
  const isEmpty =
    !name.value &&
    !email.value &&
    selectedCountryObject.value?.value === '' &&
    !acceptTerms.value;
  const isValid =
    name.value &&
    email.value &&
    validateEmail(email.value) &&
    selectedCountryObject.value?.value !== '' &&
    acceptTerms.value;
  return isEmpty || isValid;
};

const countryList = computed(() => {
  const defaultCountry = {
    value: '',
    label: t('general.selectYourCountry'),
    countryCode: '+1',
  };

  let countries = applicationSetup.value.countries;

  // Remove any existing default country entries
  countries = countries.filter(
    (country) =>
      country.value !== defaultCountry.value &&
      country.label !== defaultCountry.label,
  );

  // Add the default country at the beginning
  countries.unshift(defaultCountry);

  return countries;
});

watch(
  () => applicationSetup.value.language,
  () => {
    selectedCountryObject.value = countryList.value[0];
  },
  { deep: true },
);
</script>
<style scoped>
.underline-on-hover:hover {
  @apply cursor-pointer underline;
}

:deep(.multiselect__tags) {
  @apply h-[46px];
  @apply flex items-center py-0;
}

:deep(.multiselect__select) {
  @apply py-0;
}

:deep(.multiselect__single > div > span) {
  @apply text-sm;
}

.warning {
  @apply text-error;
}
</style>
