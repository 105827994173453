<template>
  <div :class="props.containerClass">
    <label :class="[labelClass, 'mb-2', 'block', 'text-sm', 'font-semibold']">
      {{ props.title }}
    </label>
    <Multiselect
      v-model="selected"
      track-by="value"
      placeholder=""
      :label="label"
      :disabled="isDisabled"
      :allow-empty="false"
      :can-clear="false"
      :clear-on-select="false"
      :close-on-select="true"
      :native="true"
      :options="props.options"
      :searchable="searchable"
      :show-labels="false"
      :class="props.options.length === 1 ? 'adjustPadding' : ''"
      @update:model-value="changed"
      @close="onMultiselectClose">
      <template #singleLabel="{ option }">
        <div class="flex items-center">
          <div v-if="props.icon" :class="props.iconClass">
            <component :is="props.icon" />
          </div>
          <span class="mr-auto">{{ option.label }}</span>
          <span>{{ option.rightSideLabel }}</span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex items-center justify-between">
          <div :class="('ml-9 flex items-center', optionClass)">
            <span>{{ option.label }}</span>
          </div>
          <span class="ml-auto mr-4">{{ option.rightSideLabel }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import Multiselect from 'vue-multiselect';
const props = defineProps({
  title: String,
  options: Array,
  containerClass: {
    type: [String, Array],
    default: '',
  },
  multiSelectContainerClass: {
    type: String,
    default: '',
  },
  multiSelectWrapperClass: {
    type: String,
    default: '',
  },
  mode: {
    type: String,
    default: 'Single',
  },
  selectedObject: Object,
  placeholder: String,
  loadingLabel: String,
  icon: Object,
  iconClass: String,
  labelClass: String,
  optionClass: String,
  isDisabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  searchable: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['changedOption', 'onMultiselectClose']);

const onMultiselectClose = () => {
  emit('onMultiselectClose');
};

const selected = ref('');
onMounted(() => {
  selected.value = props.options[0];

  if (props.selectedObject && Object.keys(props.selectedObject).length) {
    selected.value = props.selectedObject;
    emit('changedOption', props.selectedObject);
  }
});

const changed = (value) => {
  emit('changedOption', value);
};

watch(
  () => props.selectedObject,
  (newVal) => {
    if (Object.keys(newVal).length === 0) {
      return;
    }
    selected.value = newVal;
  },
  { deep: true },
);
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.adjustPadding::v-deep(.multiselect__tags) {
  padding: 8px 16px 0 8px;
}
::v-deep(.multiselect__tags) {
  @apply border-border;
}
::v-deep(.multiselect__single) {
  @apply mb-0;
}
::v-deep(.multiselect__single span),
::v-deep(.multiselect__option span) {
  @apply text-xs leading-6 text-amenities-icon;
}
::v-deep(.multiselect__option--selected),
::v-deep(.multiselect__option--highlight) {
  @apply text-sm text-dark;
  background: #efefef;
}
::v-deep(.multiselect__option) {
  @apply border-0;
}
::v-deep(.multiselect__element) {
  @apply border-t border-border;
}
::v-deep(.multiselect__element:first-child) {
  @apply border-0;
}

.warning :deep(.multiselect__tags) {
  @apply border-error;
}

.warning :deep(.multiselect__single span) {
  @apply text-error;
}

.warning :deep(.multiselect__select::before) {
  @apply border-x-transparent border-b-error border-t-error;
}

:deep(.multiselect--disabled) {
  @apply pointer-events-auto cursor-not-allowed opacity-100;
}

:deep(.multiselect--disabled .multiselect__select) {
  @apply hidden;
}
:deep(.multiselect__content-wrapper) {
  /* display: block !important; */
  max-height: 225px !important;
}
</style>
