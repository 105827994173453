import { ref, onMounted, onUnmounted } from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

export const useScreenSize = () => {
  const fullConfig = resolveConfig(tailwindConfig);
  const smBreakpoint = fullConfig.theme.screens.md;
  const isMobile = ref(true);

  const checkMobileView = () => {
    if (typeof window === 'undefined') return;
    const adjustedBreakpoint = `${parseInt(smBreakpoint) - 1}px`;
    isMobile.value = window.matchMedia(
      `(max-width: ${adjustedBreakpoint})`,
    ).matches;
  };

  // Run initial check
  checkMobileView();

  // Register event handlers in component setup
  const setupScreenSizeListeners = () => {
    onMounted(() => {
      checkMobileView();
      window.addEventListener('resize', checkMobileView);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkMobileView);
    });
  };

  return {
    isMobile,
    setupScreenSizeListeners,
  };
};

export const useMobileDevices = () => {
  const isMobile = ref(false);

  function isMobileDevice() {
    if (typeof window === 'undefined') return false;

    if (navigator.userAgentData) {
      if (navigator.userAgentData.mobile) {
        return true;
      }
      if (
        navigator.userAgentData.brands &&
        navigator.userAgentData.brands.length > 0
      ) {
        const mobileBrands = ['android', 'iphone', 'ipad', 'ipod'];
        return navigator.userAgentData.brands.some((brand) =>
          mobileBrands.some((mobileBrand) =>
            brand.brand.toLowerCase().includes(mobileBrand),
          ),
        );
      }
    }
    const userAgent = navigator.userAgent || window.opera;
    return /android|iphone|ipad|ipod|blackberry|windows phone/i.test(userAgent);
  }

  // Initialize the value
  isMobile.value = isMobileDevice();

  return {
    isMobile,
  };
};
