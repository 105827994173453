/** @type {import('tailwindcss').Config} */
export default {
  content: ['./public/**/*.html', './src/**/*.{js,jsx,ts,tsx,vue}'],
  theme: {
    extend: {
      screens: {
        md: '768px',
      },
      colors: {
        light: 'var(--color-light)',
        dark: 'var(--color-dark)',
        medium: 'var(--color-medium)',
        'medium-light': 'var(--color-medium-light)',
        brand: 'var(--color-brand)',
        'brand-darken': 'var(--color-brand-darken)',
        'brand-lighten': 'var(--color-brand-lighten)',
        secondary: 'var(--color-secondary)',
        border: 'var(--color-border)',
        success: 'var(--color-success)',
        warning: 'var(--color-warning)',
        error: 'var(--color-error)',
        'dark-error': 'var(--color-dark-error)',
        'btn-disabled': 'var(--color-btn-disabled)',
        shadow: 'var(--color-shadow)',
        title: 'var(--color-title)',
        'small-titles': 'var(--color-small-titles)',
        text: 'var(--color-text)',
        'bg-main': 'var(--color-bg-main)',
        'bg-header': 'var(--color-bg-header)',
        'bg-footer': 'var(--color-bg-footer)',
        'bg-footer-hover': 'var(--color-bg-footer-hover)',
        'border-footer': 'var(--color-border-footer)',
        'border-footer-hover': 'var(--color-border-footer-hover)',
        'header-icons': 'var(--color-header-icons)',
        'header-icons-hover': 'var(--color-header-icons-hover)',
        'nav-icons-border': 'var(--color-nav-icons-border)',
        'nav-icons-border-hover': 'var(--color-nav-icons-border-hover)',
        'nav-icons-bg': 'var(--color-nav-icons-bg)',
        'nav-icons-bg-hover': 'var(--color-nav-icons-bg-hover)',
        burger: 'var(--color-burger)',
        'burger-bg': 'var(--color-burger-bg)',
        'burger-border': 'var(--color-burger-border)',
        'burger-hover': 'var(--color-burger-hover)',
        'burger-bg-hover': 'var(--color-burger-bg-hover)',
        'burger-border-hover': 'var(--color-burger-border-hover)',
        'bg-card': 'var(--color-bg-card)',
        'bg-card-hover': 'var(--color-bg-card-hover)',
        'border-card': 'var(--color-border-card)',
        'border-card-hover': 'var(--color-border-card-hover)',
        'card-title': 'var(--color-card-title)',
        'card-title-hover': 'var(--color-card-title-hover)',
        'card-sub-title': 'var(--color-card-sub-title)',
        'card-sub-title-hover': 'var(--color-card-sub-title-hover)',
        'card-arrow': 'var(--color-card-arrow)',
        'card-arrow-hover': 'var(--color-card-arrow-hover)',
        'card-bg-arrow': 'var(--color-card-bg-arrow)',
        'card-bg-arrow-hover': 'var(--color-card-bg-arrow-hover)',
        'bg-card-main': 'var(--color-bg-card-main)',
        'border-card-main': 'var(--color-border-card-main)',
        'bg-card-secondary': 'var(--color-bg-card-secondary)',
        'border-card-secondary': 'var(--color-border-card-secondary)',
        'cal-arrows': 'var(--color-cal-arrows)',
        'cal-select': 'var(--color-cal-select)',
        'cal-select-text': 'var(--color-cal-select-text)',
        'cal-select-betwn': 'var(--color-cal-select-betwn)',
        'cal-select-betwn-text': 'var(--color-cal-select-betwn-text)',
        'cal-select-betwn-prices-text':
          'var(--color-cal-select-betwn-prices-text)',
        'cal-bg': 'var(--color-cal-bg)',
        'border-cal-days': 'var(--color-border-cal-days)',
        btn: 'var(--color-btn)',
        'btn-border': 'var(--color-btn-border)',
        'text-btn': 'var(--color-text-btn)',
        'btn-hover': 'var(--color-btn-hover)',
        'btn-border-hover': 'var(--color-btn-border-hover)',
        'text-btn-hover': 'var(--color-text-btn-hover)',
        'alternative-btn': 'var(--color-alternative-btn)',
        'alternative-btn-border': 'var(--color-alternative-btn-border)',
        'text-alternative-btn': 'var(--color-text-alternative-btn)',
        'alternative-btn-hover': 'var(--color-alternative-btn-hover)',
        'alternative-btn-border-hover':
          'var(--color-alternative-btn-border-hover)',
        'text-alternative-btn-hover': 'var(--color-text-alternative-btn-hover)',
        'secondary-btn': 'var(--color-secondary-btn)',
        'secondary-btn-border': 'var(--color-secondary-btn-border)',
        'secondary-text-btn': 'var(--color-secondary-text-btn)',
        'secondary-btn-hover': 'var(--color-secondary-btn-hover)',
        'secondary-btn-border-hover': 'var(--color-secondary-btn-border-hover)',
        'secondary-text-btn-hover': 'var(--color-secondary-text-btn-hover)',
        'text-btn-back': 'var(--color-text-btn-back)',
        'border-btn-back': 'var(--color-border-btn-back)',
        'bg-btn-back': 'var(--color-bg-btn-back)',
        'text-btn-back-hover': 'var(--color-text-btn-back-hover)',
        'border-btn-back-hover': 'var(--color-border-btn-back-hover)',
        'bg-btn-back-hover': 'var(--color-bg-btn-back-hover)',
        'bg-restrictions': 'var(--color-bg-restrictions)',
        'bg-btn-restrictions': 'var(--color-bg-btn-restrictions)',
        'bg-btn-restrictions-text': 'var(--color-bg-btn-restrictions-text)',
        'bg-btn-restrictions-hover': 'var(--color-bg-btn-restrictions-hover)',
        'bg-btn-restrictions-text-hover':
          'var(--color-bg-btn-restrictions-text-hover)',
        'bg-btn-promo': 'var(--color-bg-btn-promo)',
        'border-btn-promo': 'var(--color-border-btn-promo)',
        'border-btn-promo-text': 'var(--color-border-btn-promo-text)',
        'bg-btn-promo-hover': 'var(--color-bg-btn-promo-hover)',
        'border-btn-promo-hover': 'var(--color-border-btn-promo-hover)',
        'border-btn-promo-text-hover':
          'var(--color-border-btn-promo-text-hover)',
        'bg-pop': 'var(--color-bg-pop)',
        'text-pop-title': 'var(--color-text-pop-title)',
        'border-pop': 'var(--color-border-pop)',
        'info-signal': 'var(--color-info-signal)',
        'info-icon': 'var(--color-info-icon)',
        'text-tag': 'var(--color-text-tag)',
        'bg-tag': 'var(--color-bg-tag)',
        'amenities-icon': 'var(--color-amenities-icon)',
        'bg-amenities-icon': 'var(--color-bg-amenities-icon)',
        'bg-tag-discount': 'var(--color-bg-tag-discount)',
        'text-tag-discount': 'var(--color-text-tag-discount)',
        rezlock: 'var(--color-rezlock)',
        'btn-rezlock': 'var(--color-btn-rezlock)',
        'btn-text-rezlock': 'var(--color-btn-text-rezlock)',
        'btn-rezlock-hover': 'var(--color-btn-rezlock-hover)',
        'btn-text-rezlock-hover': 'var(--color-btn-text-rezlock-hover)',
      },
      fontFamily: {
        primary: 'var(--font-primary)',
        secondary: 'var(--font-secondary)',
        cal: 'var(--font-cal)',
        'cal-prices': 'var(--font-cal-prices)',
        'card-sub-title': 'var(--font-card-sub-title)',
        'card-title': 'var(--font-card-title)',
        'text-pop-title': 'var(--font-text-pop-title)',
      },
      fontSize: {
        'gc-12': [
          '0.75rem',
          {
            lineHeight: '1rem',
            letterSpacing: '-0.01em',
          },
        ],
        'gc-14': ['0.875rem', '1'],
        'gc-16': ['1rem', '1'],
        'gc-18': ['1.125rem', '1'],
        'gc-24': ['1.5rem', '1'],
        'gc-96': ['6rem', '1'],
      },
      borderRadius: {
        'gc-sm': 'var(--border-radius-sm)',
        'gc-md': 'var(--border-radius-md)',
        'gc-lg': 'var(--border-radius-lg)',
        cut: 'var(--border-radius-cut)',
        'radius-arrow': 'var(--border-radius-arrow)',
        'radius-card': 'var(--border-radius-card)',
      },
      margin: {
        '10p': '10%',
      },
      outlineWidth: {
        'border-card': 'var(--border-card)',
        'border-card-main': 'var(--border-card-main)',
      },
      borderWidth: {
        'border-nav-icons': 'var(--border-nav-icons)',
        'border-burger': 'var(--border-burger)',
        'border-card-main': 'var(--border-card-main)',
        'border-card-secondary': 'var(--border-card-secondary)',
        'border-footer': 'var(--border-footer)',
      },
    },
  },
  plugins: [],
};
