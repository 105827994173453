<template>
  <div :class="customDivClass">
    <label v-if="label" :for="id" :class="customLabelClass">{{ label }}</label>
    <input
      v-model="inputValue"
      :readonly="isReadOnly"
      :type="type"
      :id="id"
      :name="name || id"
      :placeholder="placeholder"
      :class="[
        'rounded-md border border-border px-3 py-2 text-sm focus:ring-brand',
        customInputClass,
        isDisabled ? 'cursor-not-allowed bg-secondary' : '',
      ]"
      :aria-required="required"
      @input="handleInput"
      :required="required"
      :disabled="isDisabled"
      :maxlength="maxLength"
      :autocomplete="autoComplete" />
    <button
      v-if="iconButton && iconComponent"
      type="button"
      :class="['absolute top-0 h-full', customButtonIconClass]"
      @click="emit('click')">
      <component :is="iconComponent" class="h-4 w-4" />
    </button>
  </div>
</template>

<script setup>
import { ref, shallowRef, onMounted, watch } from 'vue';

const props = defineProps({
  id: String,
  label: String,
  type: {
    type: String,
    default: 'text',
  },
  name: String,
  placeholder: String,
  customLabelClass: String,
  customInputClass: String,
  required: Boolean,
  defaultValue: {
    type: [String, Number],
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isReadOnly: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 45,
  },
  customDivClass: String,
  customButtonIconClass: String,
  iconButton: String,
  autoComplete: {
    type: String,
    default: 'off',
  },
});

watch(
  () => props.defaultValue,
  (newValue) => {
    inputValue.value = newValue;
  },
);

const emit = defineEmits(['update:text', 'click']);
const inputValue = ref(props.defaultValue);

const handleInput = () => {
  emit('update:text', inputValue.value);
};

const iconComponent = shallowRef(null);
const loadIcon = async (iconName) => {
  try {
    const iconModule = await import(`../../assets/icons/${iconName}.svg`);
    iconComponent.value = iconModule.default;
  } catch (error) {
    console.error(`Icon "${iconName}" not found.`);
    iconComponent.value = null;
  }
};

onMounted(() => {
  if (props.iconButton) {
    loadIcon(props.iconButton);
  }
});
watch(
  () => props.iconButton,
  (newIcon) => {
    if (newIcon) {
      loadIcon(newIcon);
    }
  },
);
</script>

<style scoped>
.warning input {
  @apply border-error;
}

.warning input::placeholder {
  @apply text-error;
}
</style>
