<template>
  <div class="flex h-full w-full flex-col justify-between">
    <!-- Main content in the middle -->
    <div class="flex-grow content-center">
      <h1
        class="gc-font-title mb-10 mt-5 text-center font-text-pop-title text-text-pop-title">
        {{ $t('general.logout') }}
      </h1>
      <h2 class="gc-headline-small mt-10 text-center text-xs font-light">
        {{ $t('general.sessionLogout') }}
        <br />
        {{ $t('general.invalidPersonalisedPromotions') }}
      </h2>
    </div>

    <div>
      <div class="mb-5 mt-10 flex w-full justify-around gap-2">
        <CustomButton
          :label="$t('general.logout')"
          @click="doLogout"
          customClass="primary"
          width="w-full" />
      </div>
    </div>
  </div>
</template>

<script setup>
import CustomButton from '@/components/customComponents/CustomButton.vue';
import { useUserProfile } from '@stores/userProfile.store';

const emit = defineEmits(['close', 'showLoggedOutModal']);

const userProfile = useUserProfile();

const doLogout = () => {
  document.cookie =
    'loyalty_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  userProfile.logoutUser();
  emit('showLoggedOutModal');
  emit('close');
};
</script>
