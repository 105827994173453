import './index.css';
import './assets/theme.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from './locales/i18n';

import App from './App.vue';
import router from './router';
import { install } from 'vue3-recaptcha-v2';
import * as Sentry from '@sentry/vue';

const key = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const app = createApp(App);
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const sentryEnv = import.meta.env.VITE_SENTRY_ENV;
const sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED === 'true';
if (sentryEnabled) {
  Sentry.init({
    app,
    dsn: sentryDsn,
    environment: sentryEnv,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        unblock: ['.sentry-unblock, [data-sentry-unblock]'],
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    autoSessionTracking: true,
  });
}

app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(install, {
  sitekey: key,
});
app.mount('#app');
