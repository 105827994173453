<template>
  <button
    :class="[
      'gc-font-button flex items-center justify-center space-x-2 rounded-gc-sm text-center font-secondary',
      customClass,
      props.disabled ? 'cursor-not-allowed opacity-50' : '',
      width,
      height,
    ]"
    @click="emit('click')"
    :disabled="props.disabled">
    <!-- Render icon based on position -->
    <span v-if="iconComponent && props.iconPosition === 'left'">
      <component :is="iconComponent" class="h-4 w-4" />
    </span>
    <span translate="no" class="gc-font-button" :class="spanClass">
      {{ props.label }}
    </span>
    <span v-if="iconComponent && props.iconPosition === 'right'">
      <component :is="iconComponent" class="h-4 w-4" />
    </span>
  </button>
</template>

<script setup>
import { shallowRef, watch, onMounted, computed } from 'vue';

const props = defineProps({
  label: String,
  disabled: Boolean,
  icon: String, // Name of the SVG icon
  customClass: {
    type: String,
    default: 'primary',
  },
  iconPosition: {
    type: String,
    default: 'left',
  },
  width: {
    type: String,
    default: 'w-40',
  },
  height: {
    type: String,
    default: 'h-12',
  },
  spanClass: String,
});

const emit = defineEmits(['click']);

const iconComponent = shallowRef(null);

const loadIcon = async (iconName) => {
  try {
    const iconModule = await import(`../../assets/icons/${iconName}.svg`);
    iconComponent.value = iconModule.default;
  } catch (error) {
    console.error(`Icon "${iconName}" not found.`);
    iconComponent.value = null;
  }
};

// CSS classes based on drawer position
const customClass = computed(() => {
  switch (props.customClass) {
    case 'primary':
      return `bg-btn text-text-btn md:hover:bg-btn-hover md:hover:text-text-btn-hover border border-btn-border md:hover:border-btn-border-hover`;
    case 'secondary':
      return `bg-shadow text-text-btn-back md:hover:bg-bg-btn-back-hover md:hover:text-text-btn-back-hover border border-border-btn-back md:hover:border-border-btn-back-hover`;
    case 'lockPrice':
      return `bg-btn-rezlock text-btn-text-rezlock md:hover:bg-light md:hover:text-rezlock md:hover:border md:hover:border-rezlock`;
    default:
      return '';
  }
});

onMounted(() => {
  if (props.icon) {
    loadIcon(props.icon);
  }
});

watch(
  () => props.icon,
  (newIcon) => {
    if (newIcon) {
      loadIcon(newIcon);
    }
  },
);
</script>
