import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const bearer = ref('');
  const apikey = ref('');
  const channelKey = ref('');

  const setToken = (token) => {
    bearer.value = token;
  };

  const updateToken = (token) => {
    bearer.value = token;
  };

  const setApiKey = (key) => {
    apikey.value = key;
  };

  const setChannelKey = (key) => {
    channelKey.value = key;
  };

  //action to set the api key and channel key
  const setApiKeyAndChannelKey = (key, channel) => {
    apikey.value = key;
    channelKey.value = channel ?? '';
  };

  const clearToken = () => {
    bearer.value = '';
  };

  return {
    bearer,
    apikey,
    channelKey,
    setToken,
    updateToken,
    setApiKey,
    setChannelKey,
    setApiKeyAndChannelKey,
    clearToken,
  };
});
