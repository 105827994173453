import useHttp from '@/utils/httpClient';
import { languageSetup } from './LanguageSetup';
import { CurrencyService } from './CurrencySetup';
import { CssService } from './CssSetup';

export const setupService = () => {
  const http = useHttp();
  const languageService = languageSetup();
  const currencyService = new CurrencyService();
  const cssService = new CssService();

  const getSetup = async (lang = null) => {
    const response = await http.get('setup', { language: lang });
    const data = response.data;
    return processSetupResponse(data);
  };

  const processSetupResponse = (data) => {
    const processedData = {
      ...data,
      language: languageService.getActiveLanguage(data.languages),
      languages: languageService.processLanguages(data.languages),
      currency: currencyService.getDefaultCurrency(data.currencies),
      currencySymbol: currencyService.getDefaultCurrencySymbol(data.currencies),
    };

    // Determine user language
    processedData.language = languageService.determineUserLanguage(
      processedData.hotel_id,
      processedData.languages,
    );

    return processedData;
  };

  const applyCssOverride = (hotelId, hotelTemplateCss) => {
    cssService.applyCssOverride(hotelId, hotelTemplateCss);
  };

  return {
    getSetup,
    applyCssOverride,
  };
};
