<template>
  <button
    class="box-content h-8 w-8 cursor-pointer rounded-gc-md border border-brand bg-light text-brand md:hover:bg-brand md:hover:text-light"
    :aria-label="ariaLabel"
    :disabled="disabled"
    :class="[
      buttonClass,
      props.disabled ? '!cursor-not-allowed opacity-50' : '',
    ]"
    @click="handleClick">
    <!-- Conditionally render the icon or the text -->
    <component :class="iconClass" v-if="!text" :is="icon" />
    <span :class="textClass" v-else>{{ text }}</span>
  </button>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: '',
    required: false,
  },
  textClass: {
    type: String,
    default: '',
    required: false,
  },
  buttonClass: {
    type: String,
    default: '',
    required: false,
  },
  icon: {
    type: Object,
    default: () => {},
    required: false,
  },
  iconClass: {
    type: String,
    default: '',
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  ariaLabel: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['click']);

const handleClick = (event) => {
  if (!props.disabled) {
    emit('click', event);
  }
};
</script>

<style></style>
