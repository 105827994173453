export const AUTH_CONFIG = {
  BEARER_TIMEOUT: 3600000, // 1 hour in milliseconds
  REFRESH_BUFFER: 20000, // 20 seconds buffer before token expiry
  ENDPOINTS: {
    AUTHENTICATE: 'hola',
    REFRESH_TOKEN: 'hola/again',
  },
  PARAMS: {
    API_KEY: import.meta.env.VITE_APIKEY_PARAM_NAME,
    CHANNEL_KEY: 'channelKey',
  },
};
