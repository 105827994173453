import { createRouter, createWebHistory } from 'vue-router';
import { useGoogleAnalytics } from '../services/analytics/useGoogleAnalytics';
import { useHotjar } from '../services/analytics/useHotjar';
import { useAppConfigStore } from '@stores/appConfig.store';

// Create a router instance
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
    },
    {
      path: '/search',
      name: 'search',
      meta: { fallback: '/' },
      props: { section: 'list' },
      component: () => import('../views/SearchView.vue'),
    },
    {
      path: '/room/details/:id',
      name: 'room details',
      meta: { fallback: '/search' },
      props: { section: 'roomDetails' },
      component: () => import('../views/SearchView.vue'),
    },
    {
      path: '/rate/details/:id',
      name: 'rate details',
      meta: { fallback: '/search' },
      props: { section: 'rateDetails' },
      component: () => import('../views/SearchView.vue'),
    },
    {
      path: '/extras/:id',
      name: 'extras',
      meta: { fallback: '/search' },
      props: { section: 'extras' },
      component: () => import('../views/CheckoutView.vue'),
    },
    {
      path: '/payment',
      name: 'payment',
      meta: { fallback: '/search' },
      props: { section: 'payment' },
      component: () => import('../views/CheckoutView.vue'),
    },
    {
      path: '/payment/mbway',
      name: 'mbway',
      props: { section: 'mbway' },
      component: () => import('../views/CheckoutView.vue'),
    },
    {
      path: '/guest',
      name: 'guest details',
      props: { section: 'guest' },
      component: () => import('../views/CheckoutView.vue'),
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      props: { section: 'confirmation' },
      component: () => import('../views/CheckoutView.vue'),
    },
  ],
});

// Initialize Hotjar (will only run in production)
useHotjar();

// Store the current route and hotel ID
let currentRoute = null;
let cachedHotelId = null;

const trackCurrentRoute = (hotelId) => {
  if (currentRoute && hotelId) {
    const { trackPageView } = useGoogleAnalytics();
    trackPageView(currentRoute.fullPath, hotelId);
  }
};

// Export the tracking function so it can be called from App.vue after initialization
export const trackInitialPageView = (hotelId) => {
  if (hotelId) {
    // Cache the hotel ID for future use
    cachedHotelId = hotelId;

    // Track the current route if available
    if (currentRoute) {
      trackCurrentRoute(hotelId);
    }
  }
};

// Add navigation guard for analytics
router.afterEach((to) => {
  // Store the current route
  currentRoute = to;

  // Try to get hotel_id from the store
  const appConfigStore = useAppConfigStore();
  const { applicationSetup } = appConfigStore;

  // Use cached hotel ID if available, otherwise try to get it from the store
  const hotelId = cachedHotelId || applicationSetup.value?.hotel_id;

  if (hotelId) {
    // If we have a hotel ID, track the page view
    trackCurrentRoute(hotelId);

    // Update the cached hotel ID if it wasn't set before
    if (!cachedHotelId) {
      cachedHotelId = hotelId;
    }
  }
  // If no hotel ID is available yet, the page view will be tracked
  // when trackInitialPageView is called from App.vue
});

export default router;
