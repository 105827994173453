import useHttp from '@/utils/httpClient';

export const usePixelsService = () => {
  const http = useHttp();

  const fetchPixelsConfig = async () => {
    const response = await http.get('pixels');
    return response.data;
  };

  return {
    fetchPixelsConfig,
  };
};
