import { ref, watch } from 'vue';
import { useAppConfigStore } from '@stores/appConfig.store';

export function useFavicon() {
  const appConfigStore = useAppConfigStore();
  const { applicationSetup } = appConfigStore;

  // Default favicon path
  const defaultFavicon = '/favicon.ico';
  const currentFavicon = ref(defaultFavicon);

  /**
   * Set the favicon dynamically
   * @param {string} faviconPath - Path to the favicon file
   */
  const setFavicon = (faviconPath) => {
    if (!faviconPath) return;

    // Update the favicon link element
    const linkElement = document.querySelector('link[rel="icon"]');
    if (linkElement) {
      linkElement.href = faviconPath;
      currentFavicon.value = faviconPath;
    }
  };

  /**
   * Set the favicon based on a URL
   * @param {string} url - The URL of the favicon
   */
  const setFaviconByUrl = (url) => {
    if (!url) return;
    setFavicon(url);
  };

  /**
   * Reset the favicon to the default
   */
  const resetFavicon = () => {
    setFavicon(defaultFavicon);
  };

  // Watch for changes in the hotel ID and update the favicon accordingly
  // This is optional and depends on your use case
  watch(
    () => applicationSetup.hotel_favicon,
    (newFaviconUrl) => {
      if (newFaviconUrl) {
        setFaviconByUrl(newFaviconUrl);
      }
    },
    { immediate: true },
  );

  return {
    currentFavicon,
    setFavicon,
    setFaviconByUrl,
    resetFavicon,
  };
}
