import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import useHttp from '@/utils/httpClient';
import { useAppConfigStore } from '@stores/appConfig.store';

const http = useHttp();

export const useMenus = defineStore('menus', () => {
  const websiteMenu = ref({});
  const quickLinks = ref({});
  const mobileMenu = ref({});
  const appConfigStore = useAppConfigStore();
  const { applicationSetup } = storeToRefs(appConfigStore);
  const { locale } = useI18n();
  const contactsInfo = computed(
    () => applicationSetup.value.contacts_information,
  );

  const getMenu = async () => {
    let currentLocale = locale.value;
    if (currentLocale == 'zh-cn') {
      currentLocale = 'zh';
    }

    return http
      .get('site_menu', {
        language: currentLocale,
      })
      .then((rsp) => {
        websiteMenu.value = rsp.data.websitemenu;
        mobileMenu.value = rsp.data.mobilemenu;
        quickLinks.value = rsp.data.quicklinks;
      });
  };

  return { websiteMenu, quickLinks, mobileMenu, contactsInfo, getMenu };
});
