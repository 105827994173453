import { useAuthStore } from '@/stores/auth.store';
import { TokenService } from './tokenService';
import { TokenScheduler } from './tokenScheduler';

export const useAuthentication = () => {
  const authStore = useAuthStore();
  const tokenService = new TokenService();
  const tokenScheduler = new TokenScheduler();

  const login = async () => {
    const token = await tokenService.login();
    tokenScheduler.scheduleAuthRefresh();
    return token;
  };

  return {
    login,
    bearer: authStore.bearer,
  };
};
