const necessaryCookiesData = [
  {
    name: 'PHPSESSID',
    provider: 'Guestcentric',
    purpose: 'Used to manage user sessions',
    expiry: 'Session',
  },
];

const analisysDataCookies = [
  {
    name: '_ga',
    provider: 'Google',
    purpose: 'Used to distinguish user',
    expiry: '2 years',
  },
  {
    name: '_gid',
    provider: 'Google',
    purpose: 'Used to distinguish user',
    expiry: '24 hours',
  },
  {
    name: '_gat_gcTracker',
    provider: 'Google',
    purpose: 'Throttle Request Rate',
    expiry: '1 minute',
  },
];

const functionalityDataCookies = [
  {
    name: 'GCShoppingActivations_<GCID>_BEST_RATE_GUARANTEED',
    provider: 'Guestcentric',
    purpose:
      'Popup display control. Stores a true/false flag that indicates if the popup was already displayed.',
    expiry: '10 minutes',
  },
  {
    name: 'GCShoppingActivations_<GCID>_LAST_RESERVATION',
    provider: 'Guestcentric',
    purpose:
      'Popup display control. Stores a true/false flag that indicates if the popup was already displayed.',
    expiry: '10 minutes',
  },
  {
    name: 'GCShoppingActivations_<GCID>_LAST_RESERVATIONS',
    provider: 'Guestcentric',
    purpose:
      'Popup display control. Stores a true/false flag that indicates if the popup was already displayed.',
    expiry: '10 minutes',
  },
  {
    name: 'GCShoppingActivations_<GCID>_LAST_WEEK_STAYS',
    provider: 'Guestcentric',
    purpose:
      'Popup display control. Stores a true/false flag that indicates if the popup was already displayed.',
    expiry: '10 minutes',
  },
  {
    name: 'GCShoppingActivations_<GCID>TODAY_BEST_OFFER',
    provider: 'Guestcentric',
    purpose:
      'Popup display control. Stores a true/false flag that indicates if the popup was already displayed.',
    expiry: '10 minutes',
  },
  {
    name: 'GCShoppingVisits_<GCID>',
    provider: 'Guestcentric',
    purpose: 'Count visits to control popup display',
    expiry: '1 Month',
  },
  {
    name: 'GCShoppingVisits_<GCID>',
    provider: 'Guestcentric',
    purpose: 'Display shopping recovery',
    expiry: '1 Month',
  },
];

export { necessaryCookiesData, analisysDataCookies, functionalityDataCookies };
