<template>
  <div class="flex h-full w-full flex-col justify-between">
    <!-- Main content in the middle -->
    <div class="flex-grow content-center">
      <h1
        class="gc-font-title mb-10 mt-5 text-center font-text-pop-title text-text-pop-title">
        {{ applicationSetup.loyalty.button_label }}
      </h1>
      <div class="mb-6 text-center text-small-titles md:mb-14">
        {{ applicationSetup.loyalty.message }}
      </div>
      <CustomInput
        id="signInEmail"
        customDivClass="signInEmail mb-4 text-xs w-full"
        customLabelClass="text-small-titles"
        customInputClass="w-full mt-1 h-[46px]"
        :label="$t('general.email')"
        placeholder="john.doe@mail.com"
        :defaultValue="email"
        @update:text="fillEmail"
        :class="{ warning: emailWarning }" />

      <!-- <CustomInput
        id="password"
        :label="$t('general.password')"
        customDivClass="relative mb-4 text-xs w-full"
        customLabelClass="text-small-titles"
        customInputClass="w-full mt-1 h-[46px]"
        :type="isPasswordVisibleValue ? 'text' : 'password'"
        placeholder="Enter your password"
        :iconButton="isPasswordVisibleValue ? 'eye-off' : 'eye'"
        @click="togglePasswordField"
        @update:text="fillPassword"
        customButtonIconClass="px-3 text-sm right-0 text-gray-400 !top-3"
        autoComplete="current-password" /> -->

      <div class="flex justify-end align-middle">
        <!-- <a
          href="#"
          class="cursor-pointer text-sm text-brand underline"
          @click="openRecoverPassword">
          {{ $t('general.forgotPassword') }} ?
        </a> -->

        <label class="flex items-center">
          <span class="text-sm">{{ $t('general.rememberMe') }}</span>
          <input type="checkbox" class="ml-2" v-model="rememberMe" />
        </label>
      </div>

      <div class="mt-6 flex justify-center text-sm">
        {{ $t('general.notMemberYet') }} ?
        <a
          href="#"
          class="ml-1 cursor-pointer text-brand underline"
          @click="signUp">
          {{ $t('general.signUp') }}
        </a>
      </div>
    </div>
    <div class="mb-5 mt-10 flex justify-around gap-2">
      <CustomButton
        class="signInButton"
        width="w-full"
        :label="$t('done')"
        @click="doLogin" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, inject } from 'vue';
import { useUserProfile } from '@stores/userProfile.store';
import CustomInput from '@/components/customComponents/CustomInput.vue';
import CustomButton from '@/components/customComponents/CustomButton.vue';
import { useRoute } from 'vue-router';
import { validateEmail } from '@composables/useEmailValidator';
import { useCookiesStore } from '@stores/cookies.store';
import { storeToRefs } from 'pinia';

const route = useRoute();

const applicationSetup = inject('applicationSetup');

const emit = defineEmits([
  'openRecoverPassword',
  'openSignUp',
  'showLoggedInModal',
  'close',
  'isSignInEmptyOrValid',
]);
const userProfile = useUserProfile();
// const isPasswordVisibleValue = ref(false);

const email = ref('');
// const pwd = ref('');

const emailWarning = ref(false);
// const passwordWarning = ref(false);

const rememberMe = ref(false);

const handleKeydown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    doLogin();
  }
};

onMounted(() => {
  if (
    route.query.lt &&
    route.query.ltl &&
    !document.cookie.includes('loyalty_user')
  ) {
    email.value = route.query.ltl;
  }
  // Add global keydown listener
  window.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
  // Remove global keydown listener
  window.removeEventListener('keydown', handleKeydown);
});

const signUp = () => {
  emit('openSignUp');
};

const doLogin = async () => {
  if (!email.value) {
    emit('close');
    return;
  }

  if (!validateEmail(email.value)) {
    emailWarning.value = true;
    return;
  }

  const userData = {
    login: email.value,
    // password: pwd.value,
  };
  const response = await userProfile.doLogin(userData);

  if (response.data.status) {
    emit('showLoggedInModal');

    if (rememberMe.value) {
      const userCookieData = {
        email: email.value,
        firstName: response.data.first_name,
      };
      const cookiesStore = useCookiesStore();
      const { userCookies } = storeToRefs(cookiesStore);
      if (userCookies.value?.functionalityOrPersonalizationCookies) {
        document.cookie = `loyalty_user=${JSON.stringify(userCookieData)}; path=/;`;
      }
    }
  } else {
    emailWarning.value = true;
    // passwordWarning.value = true;
  }
};

// const fillPassword = (value) => {
//   pwd.value = value;
//   passwordWarning.value = false;
// };

const fillEmail = (value) => {
  email.value = value;
  emailWarning.value = false; // Always reset warning

  document.querySelector('.signInEmail').classList.remove('warning');
  emit('isSignInEmptyOrValid', !value || validateEmail(value));
};

// const togglePasswordField = () => {
//   isPasswordVisibleValue.value = !isPasswordVisibleValue.value;
// };
</script>

<style scoped>
.warning {
  @apply text-error;
}
</style>
