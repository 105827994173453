<template>
  <!-- Teleport the drawer to the body -->
  <Teleport to="body">
    <div>
      <!-- Overlay background with blur -->
      <div
        v-if="isOpen"
        class="z-40"
        :class="
          withBlur ? 'opaque fixed inset-0 backdrop-blur-sm' : 'fixed inset-0'
        "
        @click="closeDrawer"></div>

      <!-- Drawer content with transition -->
      <transition :name="transitionName">
        <div
          v-if="isOpen"
          :class="[
            'fixed z-50 flex max-h-full flex-col rounded-t-gc-sm border border-b-0 border-border-pop bg-bg-pop shadow-lg md:min-w-[390px] md:max-w-[530px] md:rounded-l-gc-sm md:rounded-tr-none md:border-b md:border-r-0',
            positionClasses,
            drawerStyles,
          ]">
          <!-- Content Wrapper -->
          <div
            class="drawerMainContainer flex h-full max-h-full flex-col overflow-y-hidden">
            <!-- Black bar -->
            <div
              v-if="position === 'bottom'"
              class="flex w-full justify-center py-6">
              <div class="h-1 w-1/3 rounded-md bg-dark"></div>
            </div>

            <!-- Slot for drawer content -->
            <div class="font-secondary" :class="drawerContentClass">
              <slot name="drawerContent">Default drawer content</slot>
            </div>
          </div>
          <!-- Slot for drawer buttons -->
          <div v-if="$slots.drawerButtons">
            <hr />
            <div class="mx-8 my-5">
              <slot name="drawerButtons">Default drawer buttons</slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'right',
    validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
  },
  width: {
    type: String,
    default: 'w-[300px]',
  },
  height: {
    type: String,
    default: 'h-[300px]',
  },
  drawerContentClass: {
    type: String,
    default:
      'drawerContentContainer flex flex-col flex-grow max-h-full overflow-y-hidden mx-4 md:mx-10p',
  },
  withBlur: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

// Control visibility of the drawer
const isOpen = computed(() => props.modelValue);

// Function to close the drawer
const closeDrawer = () => {
  emit('update:modelValue', false);
};

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  },
);

// CSS classes based on drawer position
const positionClasses = computed(() => {
  switch (props.position) {
    case 'top':
      return `top-0 left-0 right-0`;
    case 'bottom':
      return `bottom-0 left-0 right-0`;
    case 'left':
      return `top-0 left-0 bottom-0`;
    case 'right':
      return `top-0 right-0 bottom-0`;
    default:
      return '';
  }
});

// Styles for drawer dimensions (width/height)
const drawerStyles = computed(() => {
  if (['left', 'right'].includes(props.position)) {
    return props.width || 'w-full';
  } else if (['top', 'bottom'].includes(props.position)) {
    return props.height || 'h-full';
  }
  return '';
});

// Define the transition name based on position
const transitionName = computed(() => {
  switch (props.position) {
    case 'top':
      return 'slide-down';
    case 'bottom':
      return 'slide-up';
    case 'left':
      return 'slide-left';
    case 'right':
      return 'slide-right';
    default:
      return '';
  }
});
</script>

<style scoped>
/* Transitions for drawer sliding based on position */
.slide-down-enter-active,
.slide-up-enter-active,
.slide-right-enter-active,
.slide-left-enter-active {
  transition: transform 0.5s ease-in;
}

.slide-down-leave-active,
.slide-up-leave-active,
.slide-right-leave-active,
.slide-left-leave-active {
  transition: transform 0.5s ease-out;
}
.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}
.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(100%);
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}
.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-100%);
}
.opaque {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
<style>
.no-scroll {
  @apply overflow-hidden;
}
</style>
