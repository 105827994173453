import useHttp from '@/utils/httpClient';
import { useAuthStore } from '@/stores/auth.store';
import { AUTH_CONFIG } from './config';
import { AuthenticationError, AUTH_ERROR_CODES } from './errors';

class AuthenticationService {
  constructor() {
    this.http = useHttp();
    this.authStore = useAuthStore();
    this.initialize();
  }

  initialize() {
    const { apiKey, channelKey } = this.getAuthParamsFromUrl();
    if (!apiKey) {
      throw new AuthenticationError(
        'API key is required',
        AUTH_ERROR_CODES.MISSING_PARAMS,
      );
    }
    this.authStore.setApiKeyAndChannelKey(apiKey, channelKey);
  }

  getAuthParamsFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      apiKey: urlParams.get(AUTH_CONFIG.PARAMS.API_KEY) ?? null,
      channelKey: urlParams.get(AUTH_CONFIG.PARAMS.CHANNEL_KEY) ?? null,
    };
  }

  async authenticate() {
    try {
      const response = await this.http.get(AUTH_CONFIG.ENDPOINTS.AUTHENTICATE);
      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        'Authentication failed',
        AUTH_ERROR_CODES.INVALID_CREDENTIALS,
        error,
      );
    }
  }

  async refreshToken() {
    try {
      const response = await this.http.get(AUTH_CONFIG.ENDPOINTS.REFRESH_TOKEN);
      return response.data;
    } catch (error) {
      throw new AuthenticationError(
        'Token refresh failed',
        AUTH_ERROR_CODES.TOKEN_REFRESH_FAILED,
        error,
      );
    }
  }
}

export default AuthenticationService;
