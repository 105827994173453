/**
 * Hotjar analytics service
 * This service initializes Hotjar tracking only in production environments
 */

export const useHotjar = (hjid = 5327129, hjsv = 6) => {
  // Only initialize Hotjar in production
  if (import.meta.env.VITE_APP_TYPE === 'prd') {
    // Initialize Hotjar
    window.hj =
      window.hj ||
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    window._hjSettings = { hjid, hjsv };

    // Create and append the Hotjar script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${hjid}.js?sv=${hjsv}`;
    document.head.appendChild(script);
  }
};
