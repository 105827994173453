import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useCookiesStore } from '@stores/cookies.store';
import { storeToRefs } from 'pinia';

export const useGuestDetailsProfile = defineStore('guestActiveProfile', () => {
  const guestActiveProfile = ref();

  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('activeProfile='));
  if (cookieValue) {
    guestActiveProfile.value = JSON.parse(cookieValue.split('=')[1]);
  }

  const saveActiveProfile = (userData) => {
    const cookiesStore = useCookiesStore();
    const { userCookies } = storeToRefs(cookiesStore);
    if (userCookies.value?.functionalityOrPersonalizationCookies) {
      document.cookie = `activeProfile=${JSON.stringify(userData)}; path=/;`;
    }
    guestActiveProfile.value = userData;
  };

  return {
    guestActiveProfile,
    saveActiveProfile,
  };
});
