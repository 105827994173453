import { useI18n } from 'vue-i18n';
import { format } from 'date-fns';

export const useFormattedDate = () => {
  const { d } = useI18n();

  const shortDateParsed = (startDate) => {
    const date = new Date(startDate + 'T00:00:00');

    return {
      year: d(date, { year: 'numeric' }),
      month: d(date, { month: 'short' }).replace('.', ''),
      day: d(date, { day: 'numeric' }),
      weekday: d(date, { weekday: 'long' }),
    };
  };

  const defaultFormattedDate = (
    date,
    weekDayFormat = 'short',
    monthFormat = 'short',
  ) => {
    const formattedWeekDay = d(date, { weekday: weekDayFormat }).replace(
      '.',
      '',
    );
    const formattedMonth = d(date, { month: monthFormat }).replace('.', '');
    return formattedWeekDay + ', ' + formattedMonth + ' ' + format(date, 'd');
  };

  return {
    shortDateParsed,
    defaultFormattedDate,
  };
};

export const getWeekNames = (weekFormat = 'narrow') => {
  //weekday: How the days of the week will be written. Possible values are long (Wednesday), short (Wed) or narrow (W)
  const { locale } = useI18n();

  const { format } = new Intl.DateTimeFormat(locale.value, {
    weekday: weekFormat,
  });
  let weekDays = [...Array(7).keys()].map((day) =>
    format(new Date(2021, 5, day - 1)),
  );

  return {
    weekDays,
  };
};

export const getMonthNames = (monthFormat = 'narrow') => {
  const { locale } = useI18n();

  const { format } = new Intl.DateTimeFormat(locale.value, {
    month: monthFormat,
  });
  let monthNames = [...Array(12).keys()].map((m) =>
    format(new Date(Date.UTC(2021, m % 12))),
  );

  return {
    monthNames,
  };
};

export const getDaysBetweenDates = (start, end) => {
  const arr = [];
  for (
    const dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }

  return arr;
};

export const formatDate = (date, formatStr = 'EEE, MMM d') => {
  if (!date) return '';
  return format(new Date(date), formatStr);
};
