<template>
  <transition name="modal">
    <div
      v-if="isVisible"
      class="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm"
      @click.self="closeModal">
      <div
        :class="modalClass"
        class="relative z-50 w-72 rounded-lg p-6 text-center shadow-lg md:w-[367px]">
        <h2 v-if="title" class="h-7 text-xl font-bold text-dark">
          {{ title }}
        </h2>
        <component
          v-if="showBell"
          :is="notificationIcon"
          class="absolute right-4 top-6 h-6 w-6"
          :class="{ '!top-4': !title }" />
        <p class="my-4 text-dark" v-html="description"></p>
        <div v-if="icon" class="flex w-full justify-center">
          <IconButton
            class="flex items-center justify-center rounded-gc-lg !border-dark !bg-dark px-8 py-1.5"
            :iconClass="'h-5 w-5'"
            ariaLabel="close"
            @click="closeModal"
            :icon="icon" />
        </div>
        <button
          v-else
          @click="closeModal"
          :class="buttonTextClass"
          class="w-[100px] rounded-lg bg-dark px-4 py-2">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import IconButton from '@/components/customComponents/IconButton.vue';
const notificationIcon = defineAsyncComponent(
  () => import('@icons/notification.svg'),
);

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: 'Description',
  },
  buttonText: {
    type: String,
    default: 'Close',
  },
  type: {
    type: String,
    default: 'success',
    validator: (value) => ['success', 'warning', 'danger'].includes(value),
  },
  icon: {
    type: Object,
    required: false,
  },
  showBell: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:isVisible']);

const modalClass = computed(() => {
  return {
    'bg-success': props.type === 'success',
    'bg-warning': props.type === 'warning',
    'bg-error': props.type === 'danger',
  };
});

const buttonTextClass = computed(() => {
  return {
    'text-success': props.type === 'success',
    'text-warning': props.type === 'warning',
    'text-error': props.type === 'danger',
  };
});

const closeModal = () => {
  emit('update:isVisible', false);
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  @apply opacity-0;
}
.modal-enter-from {
  @apply opacity-0;
}
.modal-enter-to {
  @apply opacity-100;
}
</style>
