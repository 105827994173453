import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import useHttp from '@/utils/httpClient';
import { useAppConfigStore } from '@/stores/appConfig.store';
import { useAuthStore } from './auth.store';
import { useGuestDetailsProfile } from '@stores/guestActiveProfile.store';

export const useUserProfile = defineStore('userProfile', () => {
  const userProfile = ref({
    isAuthenticated: false,
    email: '',
    firstName: '',
    lastName: '',
  });
  const useGuestDetailsProfileStore = useGuestDetailsProfile();

  const checkAuthFromCookie = () => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('loyalty_user'))
      ?.split('=')[1];

    if (cookieValue) {
      const userData = JSON.parse(decodeURIComponent(cookieValue));
      userProfile.value.isAuthenticated = true;
      userProfile.value.email = userData.email;
      userProfile.value.firstName = userData.firstName;
    } else {
      userProfile.value.isAuthenticated = false;
      userProfile.value.email = '';
      userProfile.value.firstName = '';
    }
  };

  // Call this method on store initialization or page load
  checkAuthFromCookie();

  const appConfigStore = useAppConfigStore();
  const { applicationSetup } = storeToRefs(appConfigStore);

  const authStore = useAuthStore();
  const { apikey, channelKey } = storeToRefs(authStore);

  const http = useHttp();

  let offersStore = null;

  const setEmail = (newEmail) => {
    userProfile.value.email = newEmail;
  };

  const setfirstName = (newName) => {
    userProfile.value.firstName = newName;
  };

  const getEmail = () => userProfile.value.email;

  const setIsAuthenticated = (isAuthenticatedValue) => {
    userProfile.value.isAuthenticated = isAuthenticatedValue;
  };

  const loadOffersStore = async () => {
    if (!offersStore) {
      const { useOffersStore } = await import('@stores/offers.store');
      offersStore = useOffersStore();
    }
    return offersStore;
  };

  const doLogin = async (userData) => {
    try {
      let url = `?apikey=${apikey.value}`;
      if (channelKey.value) {
        url += `&channelKey=${channelKey.value}`;
      }
      url = `${applicationSetup.value.hotel_id}/login_loyalty` + url;

      const { login } = userData;
      const response = await http.post(url, { login });

      if (response.data && response.data.status) {
        const activeUserData = {
          email: response.data.login,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        };
        useGuestDetailsProfileStore.saveActiveProfile(activeUserData);
        setIsAuthenticated(true);
        setEmail(login);
        setfirstName(response.data.first_name);

        await loadOffersStore();
        offersStore.getOffers(true);
      }

      return response;
    } catch (error) {
      console.error('Error doing login:', error);
    }
  };

  const doSignUp = async (userData) => {
    try {
      let url = `?apikey=${apikey.value}`;
      if (channelKey.value) {
        url += `&channelKey=${channelKey.value}`;
      }
      url = `${applicationSetup.value.hotel_id}/signup` + url;

      const { name, login, language, country_code } = userData;
      const response = await http.post(url, {
        name,
        login,
        language,
        country_code,
      });

      console.log('doSignUp response', response);
      return response;
    } catch (error) {
      console.error('Error doing signup:', error);
    }
  };

  const logoutUser = async () => {
    setIsAuthenticated(false);
    setEmail('');
    await loadOffersStore();
    offersStore.getOffers(true); // Force fetching offers
  };

  return {
    userProfile,
    setEmail,
    getEmail,
    doLogin,
    logoutUser,
    doSignUp,
    setIsAuthenticated,
    checkAuthFromCookie,
  };
});
