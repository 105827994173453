import { useAuthStore } from '@/stores/auth.store';
import { TokenService } from './tokenService';
import { AUTH_CONFIG } from './config';
import { AuthenticationError, AUTH_ERROR_CODES } from './errors';

export class TokenScheduler {
  constructor() {
    this.authStore = useAuthStore();
    this.tokenService = new TokenService();
    this.refreshTimeout = null;
    this.bearerTimeOut =
      AUTH_CONFIG.BEARER_TIMEOUT - AUTH_CONFIG.REFRESH_BUFFER;
  }

  scheduleAuthRefresh() {
    // Clear any existing timeout
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    this.refreshTimeout = setTimeout(async () => {
      try {
        const token = await this.tokenService.refreshToken();
        this.authStore.updateToken(token);
        this.scheduleAuthRefresh();
      } catch (error) {
        if (error instanceof AuthenticationError) {
          // Handle specific authentication errors
          this.authStore.clearToken();
        } else {
          throw new AuthenticationError(
            'Failed to refresh authentication',
            AUTH_ERROR_CODES.TOKEN_REFRESH_FAILED,
            error,
          );
        }
      }
    }, this.bearerTimeOut);
  }

  stopAuthRefresh() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  }
}
