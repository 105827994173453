export class CssService {
  applyCssOverride(hotelId, hotelTemplateCss) {
    const existingStyle = document.getElementById('custom-css');
    if (existingStyle) {
      existingStyle.remove();
    }

    if (hotelId === 'e1b6bbf2699176f6' || hotelId === '264ff6b426ff7337') {
      const timestamp = new Date().getTime();
      this.overrideCssFile(
        `https://static.guestcentric.net/cdn/wsbdev/wsbdev_css/be.css?v=${timestamp}`,
      );
    } else if (hotelTemplateCss) {
      this.overrideCssFile(hotelTemplateCss);
    }
  }

  overrideCssFile(file) {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = file;
    style.id = 'custom-css';
    document.head.appendChild(style);
  }
}
