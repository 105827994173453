import { useAuthStore } from '@/stores/auth.store';
import AuthenticationService from './authService';
import { AuthenticationError, AUTH_ERROR_CODES } from './errors';

export class TokenService {
  constructor() {
    this.authStore = useAuthStore();
    this.authService = new AuthenticationService();
  }

  validateToken(token) {
    if (!token || typeof token !== 'string') {
      throw new AuthenticationError(
        'Invalid token format',
        AUTH_ERROR_CODES.INVALID_CREDENTIALS,
      );
    }
  }

  async login() {
    try {
      const token = await this.authService.authenticate();
      this.validateToken(token);
      this.authStore.setToken(token);
      return token;
    } catch (error) {
      if (error instanceof AuthenticationError) {
        throw error;
      }
      throw new AuthenticationError(
        'Login failed',
        AUTH_ERROR_CODES.INVALID_CREDENTIALS,
        error,
      );
    }
  }

  async refreshToken() {
    try {
      const token = await this.authService.refreshToken();
      this.validateToken(token);
      this.authStore.updateToken(token);
      return token;
    } catch (error) {
      if (error instanceof AuthenticationError) {
        throw error;
      }
      throw new AuthenticationError(
        'Token refresh failed',
        AUTH_ERROR_CODES.TOKEN_REFRESH_FAILED,
        error,
      );
    }
  }
}
