import axios from 'axios';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth.store';

const apiResponseError = ref(false);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    apiResponseError.value = true;
    console.log('[ERROR] API Response');
    if (error.response) {
      console.log(error.response.data.message);
      console.log('response:', error.response);
      console.log('status:', error.response.status);
    }
    return Promise.reject(error);
  },
);

// Request interceptor to add auth params from store
axios.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    // Add bearer token to headers
    config.headers['Authorization'] = `Bearer ${authStore.bearer}`;

    // For GET requests, merge with existing params
    if (config.method === 'get') {
      config.params = {
        apikey: authStore.apikey,
        ...(authStore.channelKey ? { channelKey: authStore.channelKey } : {}),
        ...config.params,
      };
    }
    // For POST requests, merge with request body
    if (config.method === 'post') {
      config.data = {
        apikey: authStore.apikey,
        ...(authStore.channelKey ? { channelKey: authStore.channelKey } : {}),
        ...config.data,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default function useHttp() {
  async function get(operation, reqParams) {
    let endpoint = import.meta.env.VITE_API_URL + operation;
    let params = getGetParams(reqParams);
    return await axios.get(endpoint, {
      params: params,
    });
  }

  async function post(operation, reqParams) {
    let endpoint = import.meta.env.VITE_API_URL + operation;
    return axios.post(endpoint, reqParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function getGetParams(reqParams) {
    let params = { ...reqParams };
    let i;
    for (i in params) {
      if (Array.isArray(params[i])) {
        params[i] = JSON.stringify(params[i]);
      }
    }
    return params;
  }

  return {
    get,
    post,
    apiResponseError,
  };
}
