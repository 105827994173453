import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import useHttp from '@/utils/httpClient';
const http = useHttp();

export const useTranslationCache = defineStore('translationCache', () => {
  const translations = ref({});
  const { locale, setLocaleMessage, getLocaleMessage } = useI18n();
  const setTranslation = (data) => {
    translations.value[locale.value] = data;
  };

  const currentTranslations = computed(() => {
    return translations.value[locale.value]
      ? translations.value[locale.value]
      : '';
  });

  // Action to fetch translations from the API or cache
  const fetchAndUpdateTranslations = async () => {
    let currentLocale = locale.value;
    if (currentLocale == 'zh-cn') {
      currentLocale = 'zh';
    }
    if (!currentTranslations.value) {
      try {
        let response = await http.get('labels', {
          language: currentLocale,
        });
        setTranslation(response.data);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    }

    setLocaleMessage(locale.value, {
      ...getLocaleMessage(locale.value),
      ...currentTranslations.value,
    });
  };

  return {
    fetchAndUpdateTranslations,
  };
});
