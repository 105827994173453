import { useRouter } from 'vue-router';

export function useGlobalNavigation() {
  const router = useRouter();
  const internalRoutes = router.options.routes.map((route) => route.path);

  const goBack = () => {
    if (hasPreviousRoute()) {
      router.back();
    } else {
      const fallbackRoute = router.currentRoute.value.meta.fallback || '/';
      const query = router.currentRoute.value.query;
      router.replace({ path: fallbackRoute, query: query });
    }
  };

  const hasPreviousRoute = () => {
    if (!window.history.state.back) {
      return false;
    }
    const path = window.history.state.back.split('?')[0];
    const regex =
      /^(\/extras\/\d+\.\d+|\/rate\/details\/\d+\.\d+|\/room\/details\/\d+)$/;
    if (regex.test(path)) {
      return true;
    }

    return (
      window.history.length > 1 &&
      (window.history.state.back ? internalRoutes.includes(path) : false)
    );
  };

  return {
    goBack,
  };
}
