<template>
  <div class="flex-grow content-center overflow-y-auto text-sm md:mt-9">
    <h1
      class="gdpr-title gc-font-title pb-5 text-center font-text-pop-title text-text-pop-title">
      {{ $t('termsAndConditions') }}
    </h1>
    <div class="mt-4" v-html="$t('GDPRPolicyText')"></div>
  </div>
  <div class="mb-5 mt-10 flex">
    <CustomButton :label="$t('close')" :width="'w-full'" @click="closeDrawer" />
  </div>
</template>

<script setup>
import CustomButton from '@/components/customComponents/CustomButton.vue';
import { onMounted } from 'vue';

const emit = defineEmits(['close']);

const closeDrawer = () => {
  emit('close');
};

onMounted(() => {
  const topElement = document.querySelector('.gdpr-title');
  if (topElement) {
    topElement.scrollIntoView();
  }
});
</script>
