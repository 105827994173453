<template>
  <SignIn
    v-if="isSignInVisible"
    @close="closeLoginDrawer"
    @openRecoverPassword="openRecoverPassword"
    @openSignUp="openSignUp"
    @showLoggedInModal="onShowLoggedInModal"
    @isSignInEmptyOrValid="(value) => emit('isSignInEmptyOrValid', value)" />

  <SignUp
    v-if="isSignUpVisible"
    @close="closeLoginDrawer"
    @backToLogin="backToLogin"
    @showSignupModal="showSignupModal"
    @isSignUpEmptyOrValid="(value) => emit('isSignUpEmptyOrValid', value)" />

  <LoggedIn
    v-if="isLoggedInVisible"
    @close="closeLoginDrawer"
    @showLoggedOutModal="onShowLoggedOutModal" />
</template>

<script setup>
import SignIn from '@/components/Login/SignIn.vue';
// import RecoverPassword from '@/components/Login/RecoverPassword.vue';
import LoggedIn from '@/components/Login/LoggedIn.vue';
import SignUp from '@/components/Login/SignUp.vue';
import { useUserProfile } from '@stores/userProfile.store';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

const userProfileStore = useUserProfile();
const { userProfile } = storeToRefs(userProfileStore);
const emit = defineEmits([
  'close',
  'showLoggedInModal',
  'showLoggedOutModal',
  'showSignupModal',
  'isSignInEmptyOrValid',
]);

const isSignInVisible = ref(true);
const isSignUpVisible = ref(false);
const isLoggedInVisible = ref(false);

// const isRecoverPasswordVisible = ref(false);

onMounted(() => {
  if (userProfile.value.isAuthenticated) {
    isSignInVisible.value = false;
    isSignUpVisible.value = false;
    isLoggedInVisible.value = true;
  } else {
    isSignInVisible.value = true;
    isSignUpVisible.value = false;
    isLoggedInVisible.value = false;
  }
});

const showSignupModal = () => {
  emit('showSignupModal');
};

const onShowLoggedInModal = () => {
  emit('showLoggedInModal');
};

const onShowLoggedOutModal = () => {
  emit('showLoggedOutModal');
};

const closeLoginDrawer = () => {
  emit('close');
};

const openRecoverPassword = () => {
  // isRecoverPasswordVisible.value = true;
  isSignInVisible.value = false;
  isSignUpVisible.value = false;
  isLoggedInVisible.value = false;
};

const backToLogin = () => {
  // isRecoverPasswordVisible.value = false;
  isSignInVisible.value = true;
  isSignUpVisible.value = false;
  isLoggedInVisible.value = false;
};

const openSignUp = () => {
  isSignInVisible.value = false;
  isSignUpVisible.value = true;
  isLoggedInVisible.value = false;
};
</script>
